import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import Backlink from "components/legal-backlink"

const StyledLegalTerms = styled.section`
  padding: 60px 0;
  border-bottom-width: 0;

  h1 {
    font-size: 32px;
    text-align: center;
    margin: 0 auto 2rem;
    
      ${breakpoint.medium`
        font-size: 42px;
      `}
  }

  b {
		font-weight: 600;
  }

	h2 {
		font-weight: 600;
    font-size: 17px;
    ${breakpoint.medium`
      font-size: 21px;
    `}
  }

  .content {
    width: 100%;
    display: block;

  ${breakpoint.small`
	column-count: 2;
	column-gap: 2em;
  `}

    &:last-child {
      margin-bottom: 0;
    }

    p {
      width: 100%;
      margin-bottom: 20px;
    }
    
    ul {
      list-style: outside;
      margin-bottom: 20px;
      padding-left: 20px;
  	}
	
    li {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    a {
        color: ${colors.orange};

        &:hover,
        &.active {
          color: ${colors.purpleDark};
        }
    }

    button {
      max-width: 320px;
      background-color: ${colors.orange};
      color: ${colors.charcoal};

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
  }
`

class Section extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoadMore: this.props.showLoadMore,
      contentIsVisible: false,
    }
  }

  render = props => (
    <StyledLegalTerms contentIsVisible={this.state.contentIsVisible}>
      <Container>
        <h1>{this.props.title}</h1>
        {this.props.children}
      </Container>
    </StyledLegalTerms>
  )
}

class LegalTerms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showPlaceholder: true,
    }
  }

  hidePlaceholder = () => {
    if (this.state.showPlaceholder) {
      this.setState({
        showPlaceholder: false,
      })
    }
  }

  render = () => (
    <React.Fragment>
      <Backlink pagetitle="Website Terms of Service" />
      <Section title="Website Terms of Service">
        <div className="content visible">
          <p>This website is operated by Visby Medical, Inc. Throughout the site, the terms “Visby”, “we”, “us” and
            “our” refer to Visby Medical. Visby offers this website, including all services, tools, technology,
            products, features, and mobile apps (together, the “Services”) available from this site to you, the user,
            conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
          <p>By visiting our site and/or purchasing something from us, you engage in our “Service” and agree to be
            bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms
            and conditions and policies referenced herein and/or available by hyperlink. These website Terms of Service apply to
            all users of the site, including without limitation users who are browsers, vendors, customers, merchants,
            and/or contributors of content.</p>
          <p>Please read these website Terms of Service carefully before accessing or using our website. By accessing or using
            the Services you agree to be bound by these website Terms of Service. If you do not agree to all the terms and
            conditions of this agreement, then you may not access the website or use any Services.</p>
          <p>Any new features or tools which are added to the current store shall also be subject to the website Terms of
            Service. You can review the most current version of the website Terms of Service at any time on this page. We
            reserve the right to update, change or replace any part of these website Terms of Service by posting updates and/or
            changes to our website. It is your responsibility to check this page periodically for changes. Your
            continued use of or access to the website following the posting of any changes constitutes acceptance of
            those changes.</p>

          <p><b>ARBITRATION NOTICE: YOU AGREE THAT DISPUTES BETWEEN YOU AND US ARISING OUT OF OR RELATED TO THESE WEBSITE TERMS
            OF SERVICE WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHTS TO A JURY TRIAL AND
            TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION, AS FURTHER SET FORTH HEREIN. SECTION 19
            BELOW HAS MORE INFORMATION AND HOW YOU CAN OPT OUT OF ARBITRATION.</b></p>

          <h2>Section 1 – Acceptable Use of the Services</h2>
          <p>By agreeing to these website Terms of Service, you represent that you are at least the age of majority in your
            jurisdiction of residence, or that you are the age of majority in your jurisdiction of residence and you
            have given us your consent to allow any of your minor dependents to use the Services.</p>
          <p>By using or accessing the Services, you represent and warrant that you will not:</p>
          <ul>
            <li>use our products for any illegal, unlawful, or unauthorized purpose, or to solicit others to perform or
              participate in any unlawful acts
            </li>
            <li>upload or transmit viruses, worms, or any other type of malicious code that will or may be used in any
              way that will affect the functionality or operation of the Service or of any related website, other
              websites, or the Internet
            </li>
            <li>attempt to or actually disable, overburden, damage, impair, or override the Services or interfere with
              other party’s use of the Services, including any security components
            </li>
            <li>use the Services for any commercial purpose or financial gain</li>
            <li>misrepresent your identity or affiliation in any way (including, without limitation, by using email
              addresses or usernames associated any other natural person that is not you)
            </li>
            <li>"stalk" or otherwise harass another user or Visby</li>
            <li>upload, post, email, share, or otherwise transmit any material to either Visby or a third-party platform
              (including social media) that may be harass, disparage, or otherwise limit another user’s use or enjoyment
              of the Services
            </li>
          </ul>

          <p>In addition, you are prohibited from using the site or its content:</p>
          <ul>
            <li>to violate any international, federal, provincial or state regulations, rules, laws, or local
              ordinances
            </li>
            <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender,
              sexual orientation, religion, ethnicity, race, age, national origin, or disability
            </li>
            <li>to submit false or misleading information</li>
            <li>to collect or track the personal information of others</li>
            <li>to spam, phish, pharm, pretext, spider, crawl, or scrape</li>
            <li>for any obscene or immoral purpose</li>
            <li>to interfere with or circumvent the security features of the Service or any related website, other
              websites, or the Internet
            </li>
          </ul>

          <p>If you choose, or are provided with, a username, password or any other piece of information as part of our
            security procedures, you must treat such information as confidential, and you must not disclose it to any
            other person or entity. You acknowledge that your account is personal to you and you agree not to provide
            any other person with access to the Services or portions of the Services using your username, password, or
            other security information. You agree to notify us immediately of any unauthorized access to or use of your
            username or password or any other breach of security.</p>
          <p>A breach or violation of any of the Terms will result in an immediate termination of your Services. You
            acknowledge and agree that you are solely responsible for (and that Visby has no responsibility to you or to
            any third party for) any breach of your obligations under these Terms and for the consequences (including
            any loss or damage which Visby may suffer) of any such breach. You agree to defend and indemnify Visby and
            its affiliates against any liability, costs, or damages arising out of the breach of the representation.</p>

          <h2>Section 2 - General Conditions</h2>
          <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
          <p><b>Security.</b> We employ physical, technical and administrative safeguards to guard against unauthorized
            disclosure or access to your personal information. However, you acknowledge that security safeguards, by
            their nature, are capable of circumvention and Visby does not and cannot guarantee that personal information
            about you will not be accessed by unauthorized persons capable of overcoming such safeguards (e.g., hackers)
            who may use viruses, worms, trojan horses, and other undesirable and malicious data and software to obtain
            access to or damage our Services. You understand that your content (not including credit card information),
            may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to
            conform and adapt to technical requirements of connecting networks or devices. Credit card information is
            always encrypted during transfer over networks.</p>
          <p><b>Your Proprietary Rights.</b> By uploading information to the Service or submitting any materials to us,
            you automatically grant (or warrant that the owner of such rights has expressly granted) Visby a perpetual,
            worldwide, royalty-free, irrevocable, nonexclusive right and license to use, reproduce, modify, display,
            adapt, publish, and translate your information to enable us to operate the Services and to make your
            information accessible to you and other users. If you choose to share any physical sample with us to test or
            analyze, you give Visby and its contractors, successors and assignees permission to extract information from
            your sample, and disclose the results to you and to others you specifically authorize.</p>
          <p>The headings used in this agreement are included for convenience only and will not limit or otherwise
            affect these Terms.</p>

          <h2>Section 3 – Intellectual property rights</h2>
          <p>The Services contain material, such as software, text, graphics, images, video, audio and other material
            (collectively, “Content”). No Content from the Services may be copied, reproduced, modified, republished,
            uploaded, posted, transmitted, reprinted or distributed in any way without the express prior written
            permission of Visby, except that you may download or print a limited number of copies of the Services for
            your personal, noncommercial, home use only, provided you keep intact all copyright and other proprietary
            notices. The Content may be owned by us or may be provided through arrangements with others, including other
            users of the Services or third-party content providers. The Content is protected by copyright under United
            States laws. Unauthorized use of the Content may violate copyright, trademark, and other laws. Our name,
            brands, logos, slogans and other trademarks are our trademarks. All other names, brands, logos, product and
            service names, and designs appearing on the Services are the trademarks of their respective owners. You may
            not use such trademarks without our prior written permission.</p>
          <ul>
            <li>Your computer may temporarily store copies of the Content in RAM incidental to your accessing and
              viewing those materials.
            </li>
            <li>You may store files that are automatically cached by your web browser for display enhancement
              purposes.
            </li>
            <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your
              computer or mobile device solely for your own personal, non-commercial use.
            </li>
            <li>If we provide social media features, such as allowing you to share items you find in our news section
              through social media links in those posts, you may take such actions as are enabled by such features.
            </li>
          </ul>

          <p>You must not:</p>
          <ul>
            <li>Modify copies of any Content from the Services;</li>
            <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the
              accompanying text;
            </li>
            <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials
              from the Services; or,
            </li>
            <li>Access or use for any commercial purposes any part of the Services or any Content available through the
              Services.
            </li>
          </ul>

          <h2>Section 4 – Trademarks</h2>
          <p>All of the trademarks and logos displayed on this website are registered and unregistered trademarks of Visby Medical, Inc.
            Unauthorized use of any of the trademarks and logos, including product trade dress, appearing on this website is not permitted.</p>
          <p><b>Copyright</b><br />
            Visby Medical, Inc. owns all of the copyright and other intellectual property rights, including but not limited to
            product designs, photos, videos, artwork and design rights in our website and in the material appearing on
            our website, or is authorized to use such intellectual property by the owners thereof. All rights in the intellectual
            property appearing on this website are reserved. Written permission is required for any and all use of the
            Visby Medical, Inc. Intellectual Property.</p>

          <h2>Section 5 - Accuracy, Completeness &amp;Timeliness of Information</h2>
          <p>The information presented on or through the Service is made available solely for general information
            purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you
            place on such information is strictly at your own risk. We disclaim all liability and responsibility arising
            from any reliance placed on such information by you or any other visitor to the Service, or by anyone who
            may be informed of any of its contents. This Service may include content provided by third parties,
            including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators
            and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and
            responses to questions and other content, other than the content we provide, are solely the opinions and the
            responsibility of the person or entity providing those materials. These materials do not necessarily reflect
            our opinion. We are not responsible, or liable to you or any third party, for the content or accuracy of any
            materials provided by any third parties. </p>
          <p>This Site is Not a Source of Medical Advice or Diagnosis. The content of this site is general in nature and
            provided for informational purposes only; it is presented in summary form and is neither intended nor
            recommended as a substitute for professional medical advice or diagnosis. You should not use or rely on the
            content of this site for diagnosing a health problem or disease. You should always seek the advice of your
            physician or other qualified health provider regarding any medical condition or treatment. Nothing contained
            on our site is intended to be used for medical diagnosis or treatment. You should never disregard medical
            advice or delay seeking medical advice because of content you encounter on this site.</p>
          <p>This site may contain certain historical information. Historical information, necessarily, is not current
            and is provided for your reference only. We reserve the right to modify the contents of this site at any
            time, but we have no obligation to update any information on our site. You agree that it is your
            responsibility to monitor changes to our site.</p>
          <p>We may also, in the future, offer new services and/or features through the website (including, the release
            of new tools and resources). Such new features and/or services shall also be subject to these website Terms of
            Service.</p>
          <p>THE SERVICES AND CONTENT ARE PROVIDED BY VISBY (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS IS” BASIS AND
            WITHOUT WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
            APPLICABLE LAW, VISBY (AND ITS LICENSORS AND SUPPLIERS) DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
            INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES'
            RIGHTS, AND FITNESS FOR A PARTICULAR PURPOSE. VISBY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING
            THE USE OR THE RESULTS OF THE USE OF THE SERVICES OR CONTENT IN TERMS OF THEIR CORRECTNESS, ACCURACY,
            RELIABILITY, OR OTHERWISE. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
            EXCLUSION MAY NOT APPLY TO YOU.</p>

          <h2>Section 6 – Modifications to the Service and Prices</h2>
          <p>Service availability, price and time-related information appearing on the Services are subject to change
            without notice. Visby is not responsible for any such changes and advises you to confirm all specific terms
            appearing on the or through the Services before acting in reliance on such terms. We reserve the right at
            any time to modify or discontinue the Service (or any part or content thereof) without notice at any
            time.</p>
          <p>We shall not be liable to you or to any third party for any modification, price change, suspension or
            discontinuance of the Service.</p>

          <h2>Section 7 – Products or Services (if applicable)</h2>
          <p>Certain products or services may be available exclusively online through the website. These products or
            services may have limited quantities and are subject to return or exchange only according to our <Link
              to="/legal/conditions/">Terms and Conditions of Sale</Link>. </p>
          <p>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person,
            geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right
            to limit the quantities of any products or services that we offer. All descriptions of products or product
            pricing are subject to change at any time without notice, at the sole discretion of us. We reserve the right
            to discontinue any product at any time. Any offer for any product or service made on this site is void where
            prohibited.</p>
          <p>We may, from time to time, host, conduct, support, or affiliate the Visby Services with sweepstakes,
            promotions, contests, giveaways, or other reward opportunities (collectively “Contests”). Such Contests
            shall be governed by additional terms, and may be subject to limitations based on your location. Visby
            reserves the right to limit, suspend, or cancel any Contest without notice.</p>

          <h2>Section 8 – Accuracy of Billing &amp;Account Information</h2>
          <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel
            quantities purchased per person, per household or per order. These restrictions may include orders placed by
            or under the same customer account, the same credit card, and/or orders that use the same billing and/or
            shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by
            contacting the e-mail and/or billing address/phone number provided at the time the order was made. We
            reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers,
            resellers or distributors.</p>
          <p>You agree to provide current, complete and accurate purchase and account information for all purchases made
            from Visby. You agree to promptly update your account and other information, including your email address
            and credit card numbers and expiration dates, so that we can complete your transactions and contact you as
            needed. If you fail to promptly update any of the foregoing information, you agree that we may continue
            charging you for any use of the Service under your billing account unless you have terminated your
            Service.</p>
          <p>For more detail, please review our <Link to="/legal/conditions/">Terms and Conditions of Sale</Link>.</p>


          <h2>Section 9 – Geographic Restrictions</h2>
          <p>Visby is based in the United States. We provide this Service for use only by persons located in the United
            States. We make no claims or representations that the Site or any of its content is accessible or
            appropriate outside of the United States. Access to the Site may not be legal by certain persons or in
            certain countries. If you access the Service from outside the United States, you do so on your own
            initiative and are responsible for compliance with local laws. </p>

          <h2>Section 10 - Third Party Links, Content, and Tools</h2>
          <p>We are not responsible for examining or evaluating any products, advice or services offered by any third
            parties whose icons, products, advice, services, tools, or hyperlinks appear on or through the Services.
            This includes links contained in advertisements, including banner advertisements and sponsored links. You
            acknowledge and agree that we provide access to such tools “as is” and “as available” without any
            warranties, representations or conditions of any kind and without any endorsement. We shall have no
            liability whatsoever arising from or relating to your use of optional third-party tools.</p>
          <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and
            you should ensure that you are familiar with and approve of the terms on which tools are provided by the
            relevant third-party provider(s). Third-party products and services represented on the Services are not
            necessarily endorsed or recommended by Visby and we disclaim all responsibility regarding the performance or
            the use of third-party products and services. We are not liable for any harm or damages related to the
            purchase or use of goods, services, resources, content, or any other transactions made in connection with
            any third-party websites.</p>

          <h2>Section 11 – User Comments, Feedback &amp;Other Submissions</h2>
          <p>If, at our request or without a request from us, you send certain specific submissions (for example contest
            entries) or you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by
            email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without
            restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that
            you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2)
            to pay compensation for any comments; or (3) to respond to any comments.</p>
          <p>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion
            are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable
            or violates any party’s intellectual property or these website Terms of Service.</p>
          <p>You agree that your comments will not violate any right of any third-party, including copyright, trademark,
            privacy, personality or other personal or proprietary right. You further agree that your comments will not
            contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other
            malware that could in any way affect the operation of the Service or any related website. You may not use a
            false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as
            to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We
            take no responsibility and assume no liability for any comments posted by you or any third-party.</p>

          <h2>Section 12 – Personal Information</h2>
          <p>Our collection, use, and transfer of personal information through the Service is governed by our <Link
            to="/legal/privacy-policy/">Privacy Policy</Link>.</p>

          <h2>Section 13 – Errors, Inaccuracies &amp;Omissions</h2>
          <p>Occasionally there may be information on our site or in the Service that contains typographical errors,
            inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product
            shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies
            or omissions, and to change or update information or cancel orders if any information in the Service or on
            any related website is inaccurate at any time without prior notice (including after you have submitted
            your order).</p>
          <p>We undertake no obligation to update, amend or clarify information in the Service or on any related
            website, including without limitation, pricing information, except as required by law. No specified update
            or refresh date applied in the Service or on any related website, should be taken to indicate that all
            information in the Service or on any related website has been modified or updated.</p>

          <h2>Section 14 – Disclaimer of Warranties; Limitation of Liability</h2>
          <p>VISBY DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED ON AND AVAILABLE THROUGH THE SERVICES WILL BE
            UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVER THAT
            MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
          <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the
            service at any time, without notice to you.</p>
          <p>In no case shall Visby, our directors, officers, employees, affiliates, agents, contractors, interns,
            suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct,
            indirect, incidental, punitive, special, or consequential damages of any kind, including, without
            limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar
            damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising
            from your use of any of the service or any products procured using the service, or for any other claim
            related in any way to your use of the service or any product, including, but not limited to, any errors
            or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the
            service or any content (or product) posted, transmitted, or otherwise made available via the service,
            even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or
            the limitation of liability for consequential or incidental damages, in such states or jurisdictions,
            our liability shall be limited to the maximum extent permitted by law.</p>

          <h2>Section 15 – Indemnification</h2>
          <p>You agree to indemnify, defend and hold harmless Visby and our parent, subsidiaries, affiliates,
            partners, officers, directors, agents, contractors, licensors, service providers, subcontractors,
            suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’
            fees, made by any third party due to or arising out of your breach of these website Terms of Service or the
            documents they incorporate by reference, or your violation of any law or the rights of a third
            party.</p>

          <h2>Section 16 – Severability</h2>
          <p>In the event that any provision of these website Terms of Service is determined to be unlawful, void or
            unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by
            applicable law, and the unenforceable portion shall be deemed to be severed from these website Terms of Service,
            such determination shall not affect the validity and enforceability of any other remaining
            provisions.</p>

          <h2>Section 17 – Termination</h2>
          <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the
            termination of this agreement for all purposes. These website Terms of Service are effective unless and until
            terminated by either you or us. You may terminate these website Terms of Service at any time by notifying us
            that you no longer wish to use our Services, or when you cease using our site.</p>
          <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or
            provision of these website Terms of Service, we also may terminate this agreement at any time without notice and
            you will remain liable for all amounts due up to and including the date of termination; and/or
            accordingly may deny you access to our Services (or any part thereof).</p>
          <p>The provisions of these Terms concerning security, representations and warranties, assignment,
            prohibited activities, copyrights, trademarks, disclaimer, limitation of liability, indemnity, dispute
            resolution, and jurisdictional issues shall survive any such termination. You agree that if your use of
            this Site or the Services is terminated pursuant to these Terms, you will not attempt to use this Site
            or our Services, and further agree that if you violate this restriction after being terminated, you will
            indemnify and hold Visby harmless from any and all liability that may incur therefore.</p>

          <h2>Section 18 – Entire Agreement</h2>
          <p>The failure of us to exercise or enforce any right or provision of these website Terms of Service shall not
            constitute a waiver of such right or provision.</p>
          <p>These website Terms of Service and any policies or operating rules posted by us on this site or in respect to
            the Service constitutes the entire agreement and understanding between you and us and govern your use of
            the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether
            oral or written, between you and us (including, but not limited to, any prior versions of the website Terms of
            Service).</p>
          <p>Any ambiguities in the interpretation of these website Terms of Service shall not be construed against the
            drafting party.</p>

          <h2>Section 19 – Governing Law</h2>
          <p>These website Terms of Service and any separate agreements whereby we provide you Services shall be governed by
            and construed in accordance with the laws of California.</p>

          <h2>Section 20 – Dispute Resolution</h2>
          <p>PLEASE READ THIS SECTION 18 CAREFULLY BECAUSE IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS, INCLUDING
            YOUR RIGHT TO FILE A LAWSUIT IN COURT. THIS SECTION 19 REQUIRES YOU TO AGREE TO RESOLVE ALL DISPUTES
            BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION.</p>
          <p>If you believe you have a dispute or claim against us arising out of your use of the Services or under
            these Terms, you agree to first discuss the matter informally with us for at least 30 days. To do that,
            you must give us written notice, which will include your full name and contact information, the nature
            and basis giving rise to your dispute or claim and your proposed resolution, sent to the address
            below.</p>
          <p>If you are located in, are based in, have offices in, or do business in a jurisdiction in which this
            section is enforceable, you understand and agree that any dispute, claim, or controversy arising under
            or in connection with these Terms, including your use and access to the Services or any other content,
            including, but not limited to, claims as to whether any services rendered by Visby or persons employed
            or engaged by Visby were unnecessary or unauthorized or were improperly, negligently, or incompetently
            rendered, shall be finally and exclusively resolved by binding arbitration under the American
            Arbitration Association’s (“AAA”) Commercial Arbitration Rules and Mediation Procedures and
            Consumer-Related Disputes Supplementary Procedures, and not by a lawsuit or resort to court process,
            except as California law provides for judicial review of arbitration proceedings. Each party retains the
            right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the
            actual or threatened infringement, misappropriation or violation of a party’s copyrights, trademarks,
            trade secrets, patents, or other intellectual property rights. It is your responsibility to pay any AAA
            filing, administrative and arbitrator fees as set forth in the AAA Rules.</p>
          <p><b>YOU ACKNOWLEDGE AND AGREE THAT BOTH PARTIES TO THE TERMS WAIVE THE RIGHT TO A TRIAL BY JURY OR TO
            PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
            The parties further agree that the arbitrator, and not any federal, state or local court or agency,
            shall have exclusive authority to resolve any dispute relating to the interpretation, applicability,
            enforceability or formation of this agreement to arbitrate. Any such controversy or claim shall be
            arbitrated on an individual basis, unless both parties otherwise agree in writing. The arbitration shall
            be held in Santa Clara County, California, U.S.A.</b></p>
          <p>If you are not located in, are not based in, do not have offices in, and do not do business in the
            United States, any arbitration between you and Visby will be finally settled under the Rules of
            Arbitration of the International Chamber of Commerce (“ICC Rules”) by one or more arbitrators appointed
            in accordance with the ICC Rules and will be administered by the International Court of Arbitration of
            the International Chamber of Commerce. You agree that such arbitration will be located in the Northern
            District of California, and waive any objection to such jurisdiction or venue.</p>
          <p>This arbitration provision is governed by the Federal Arbitration Act. You can opt out of this
            arbitration provision within 30 days of the date that you first agreed to these Terms. To opt out, you
            must send your name, residence address and email address used for your Visby account, and a clear
            statement that you want to opt out of this arbitration provisions to Visby Medical, 3010 North First
            Street, San Jose, CA 95134. Should you choose to withdraw from the arbitration provision, all other
            provisions of the Terms shall remain in full force and effect.</p>
          <p>If for any reason a claim proceeds in court rather than arbitration, each party waives any right to a
            jury trial. You agree to the personal jurisdiction by and venue in the state courts of Santa Clara
            County in the State of California or a United States District Court, Northern District of California,
            and waive any objection to such jurisdiction or venue.</p>

          <h2>Section 21 - Electronic Signatures</h2>
          <p>You acknowledge that by using the Service, you are indicating your intent to sign the relevant document
            or record acknowledging your consent to receive all Visby communications through electronic
            communications (i.e., email), and that this consent shall constitute your signature. In addition, you
            are confirming that (1) your system meets the requirements set forth above, (2) you agree to receive
            disclosures, communications and documents electronically, and (3) you are able to access and retain
            information presented at our website or via electronic communication.</p>

          <h2>Section 22 – Changes to Website Terms of Service</h2>
          <p>You can review the most current version of the website Terms of Service at any time at this page. We may make
            changes, supplement or update these Terms from time to time. The revised Terms will be effective on or
            after the posted date. You acknowledge and agree that if you use the Services after the date on which
            the Terms have changed Visby will treat your use as acceptance of the updated Terms.</p>
          <p>If we make a material change to the Terms, we will provide you with notice thirty (30) days prior to
            the effective date of the changes by posting a notice on our website and by sending you an email to the
            email address we have for you. Unless you notify us within thirty (30) days from the time you receive
            notice of the new terms that you do not agree to the terms, you will be deemed to have agreed to the new
            Terms. You understand that our relationship with you may be governed by additional terms or
            agreement.</p>

          <h2>Section 23 – Contact Information</h2>
          <p>Questions about the website Terms of Service should be sent to us at <a
            href="mailto:legal@visby.com">legal@visby.com</a>.</p>

        </div>
      </Section>
    </React.Fragment>
  )
}

export default LegalTerms

export const Head = () => (
  <Seo
        title="Website Terms of Service"
        description="Visby Medical Website Terms of Service"
        image="/meta/legal-terms-of-service.jpg"
  />
)

